var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-intro__info"
  }, [_c('info-section', {
    staticClass: "my-n16px my-sm-n24px",
    attrs: {
      "hideDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('info-groups', _vm._b({}, 'info-groups', {
    product: _vm.product
  }, false))], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('info-like', _vm._b({}, 'info-like', {
    product: _vm.product
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('info-share', _vm._b({}, 'info-share', {
    product: _vm.product
  }, false))], 1)], 1)], 1)], 1)], 1), _c('info-section', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('info-flags', _vm._b({
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px mb-md-8px"
  }, 'info-flags', {
    product: _vm.product
  }, false)), _c('h3', {
    staticClass: "product-intro__name-text"
  }, [_vm._v(_vm._s(_vm.product.name) + " " + _vm._s(_vm.product.code || ""))])], 1)], 1), _c('div', {
    staticClass: "pt-16px pt-md-24px"
  }, [_c('info-rating', _vm._b({}, 'info-rating', {
    product: _vm.product
  }, false))], 1)], 1), _c('info-section', [_c('info-price', _vm._b({}, 'info-price', {
    product: _vm.product
  }, false))], 1), _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('info-shipping', _vm._b({}, 'info-shipping', {
    product: _vm.product
  }, false))], 1), _c('div', {
    staticClass: "d-none d-lg-block"
  }, [_vm._t("form"), _vm._t("actions")], 2), _vm._t("foot")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }