<template>
    <v-list-item-content>
        <v-row align="center" class="row--sm">
            <v-col cols="auto">
                <v-card>
                    <v-img width="60" :aspect-ratio="1 / 1" :src="value?.thumb?.url">
                        <template #place-holder>
                            <v-icon>mdi-image-broken-variant</v-icon>
                        </template>
                    </v-img>
                </v-card>
            </v-col>
            <v-col>
                <div class="font-size-14 line-height-135">
                    <div v-if="value?.flags?.length" class="font-weight-bold">{{ value?.flags?.map((text) => `[${text}]`).join(" ") }}</div>
                    <div>{{ value?.name }}</div>
                    <div class="mt-8px mt-md-10px">
                        <div v-if="discountPrice" class="text-decoration-line-through grey--text text--lighten-1">
                            {{ value.price.format() }}
                        </div>
                        <span v-if="!value.sells || value.stock == 0" class="grey--text text--lighten-1">품절</span>
                        <div v-else>
                            <span v-if="discountRate" class="font-weight-medium pr-4px">{{ discountRate }}%</span>
                            <span>{{ salePrice.format() }}원</span>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-list-item-content>
</template>

<script>
import { mapGetters } from "vuex";
import { initProduct } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initProduct },
    },
    computed: {
        ...mapGetters(["getDiscountPrice", "getDiscountRate", "getSalePrice"]),
        discountPrice() {
            return this.getDiscountPrice(this.value);
        },
        discountRate() {
            return this.getDiscountRate(this.value);
        },
        salePrice() {
            return this.getSalePrice(this.value);
        },
    },
};
</script>

<style>
</style>