<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <v-row class="row--contents flex-xl-nowrap">
                <v-col v-if="$vuetify.breakpoint.xlOnly" cols="12" xl="auto">
                    <div class="w-xl-240px">
                        <!-- PC -->
                        <product-list-filter :categoryCode="$route.query.category" class="d-none d-xl-block" v-on="{ search }" />
                    </div>
                </v-col>
                <v-col>
                    <div class="mb-20px">
                        <product-list-categories />
                    </div>
                    <div class="mb-20px">
                        <v-row align="center">
                            <v-col cols="12" md="">
                                <tit-wrap-subtitle titAlign="text-center text-md-left" class="mb-0">
                                    <template slot="tit">
                                        <template v-if="$route.query.searchValue">
                                            {{ $route.query.searchValue }}
                                        </template>
                                        <template v-else-if="category?.name">
                                            {{ category?.name }}
                                        </template>
                                        <template v-else> 전체보기 </template>
                                    </template>
                                    <template slot="subTit">
                                        <template v-if="$route.query.searchValue"> 키워드검색 </template>
                                    </template>
                                </tit-wrap-subtitle>
                            </v-col>
                            <v-col cols="12" md="auto" class="text-right">
                                <span class="font-size-14">
                                    검색결과 <span class="secondary--text">{{ summary.totalCount.format() }}</span> 개
                                </span>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <product-list-sort-select />
                            </v-col>
                            <!-- Moblie -->
                            <v-col cols="12" md="auto" class="d-xl-none">
                                <product-list-filter :categoryCode="$route.query.category" />
                            </v-col>
                        </v-row>
                    </div>

                    <!-- 상품 리스트 -->
                    <section class="product-lists">
                        <div class="product-lists-body">
                            <shop-product-items>
                                <shop-product-item v-for="(product, index) in products" :key="index" :value="product" />
                            </shop-product-items>

                            <div class="product-lists__pagination">
                                <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                            </div>
                        </div>
                    </section>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ProductListFilter from "@/components/client/shop/products/filter/product-list-filter.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";
import ProductListSortSelect from "@/components/client/shop/products/list/product-list-sort-select.vue";
import ProductListCategories from "@/components/client/shop/products/list/product-list-categories.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapSubtitle,
        ShopProductItem,
        ShopProductItems,
        ProductListFilter,
        PaginationComponent,
        ProductListSortSelect,
        ProductListCategories,
    },
    metaInfo() {
        return this.seo;
    },
    data: () => ({
        products: [],

        limit: 24,
        summary: { totalCount: 0 },

        loading: false,

        attrs_input,
    }),
    computed: {
        ...mapState(["categories"]),
        categories_children() {
            return this.categories.flatMap(({ children }) => children);
        },
        categories_grandChildren() {
            return this.categories_children.flatMap(({ children }) => children);
        },
        categories_granderChildren() {
            return this.categories_grandChildren.flatMap(({ children }) => children);
        },
        category() {
            const categoryCode = this.$route.query.category;
            if (categoryCode) return this.getCategory(this.categories, categoryCode) ?? this.getCategory(this.categories_children, categoryCode) ?? this.getCategory(this.categories_grandChildren, categoryCode) ?? this.getCategory(this.categories_granderChildren, categoryCode);
        },
        seo() {
            if (this.category?.seoEnabled) return this.category?.seo;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.category;
            query._category = this.category?._id;
            if (!query._category) delete query._category;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.products = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                params = { ...params };
                var { summary, products } = await api.v1.shop.products.gets({
                    headers: { skip, limit },
                    params,
                });

                if (this.params?._category == params?._category) {
                    this.summary = summary;
                    this.products = products;
                }
            } finally {
                this.loading = false;
            }
        },

        getCategory(categories, categoryCode) {
            return categories.find(({ code }) => code == categoryCode);
        },
    },
};
</script>
