<template>
    <v-sheet>
        <template v-if="product.discountPrice">
            <span class="font-size-14 grey--text text--lighten-1 text-decoration-line-through">
                {{ product.price.format() }}
            </span>
        </template>
        <template v-if="!product.sells || product.stock == 0">
            <em class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
        </template>
        <template v-else>
            <div class="d-flex align-center font-size-18 font-size-lg-28 font-weight-bold">
                <template v-if="discountPrice">
                    <span class="secondary--text mr-4px mr-md-8px">
                        {{ `${discountRate}%` }}
                    </span>
                </template>
                <span class="d-flex flex-wrap align-center grey--text text--darken-4">
                    {{ salePrice.format() }}
                    <small class="font-size-14 font-weight-regular pl-2px">원</small>
                </span>
            </div>
        </template>
    </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import { initProduct } from "@/assets/variables/inits";

export default {
    props: {
        product: { type: Object, default: initProduct },
    },
    computed: {
        ...mapGetters(["getDiscountPrice", "getDiscountRate", "getSalePrice"]),
        discountPrice() {
            return this.getDiscountPrice(this.product);
        },
        discountRate() {
            return this.getDiscountRate(this.product);
        },
        salePrice() {
            return this.getSalePrice(this.product);
        },
    },
};
</script>

<style>
</style>