var render = function render(){
  var _vm$order, _vm$setting, _vm$setting$shop, _vm$setting2, _vm$setting2$shop, _vm$setting3, _vm$setting3$shop;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [!_vm.loading ? [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_vm.success ? _c('icon-check') : _c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-alert")])], 1), ((_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : _vm$order.paymentMethod) == 'account' ? [_c('div', {
    staticClass: "tit"
  }, [_vm._v("주문이 정상적으로 완료 되었습니다.")]), _c('div', {
    staticClass: "mt-8px"
  }, [_c('v-card', {
    staticClass: "d-inline-block",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px py-md-12px"
  }, [_c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_c('li', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("입금계좌 : " + _vm._s((_vm$setting = _vm.setting) === null || _vm$setting === void 0 ? void 0 : (_vm$setting$shop = _vm$setting.shop) === null || _vm$setting$shop === void 0 ? void 0 : _vm$setting$shop.accountNumber))]), _c('li', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("예금주 : " + _vm._s((_vm$setting2 = _vm.setting) === null || _vm$setting2 === void 0 ? void 0 : (_vm$setting2$shop = _vm$setting2.shop) === null || _vm$setting2$shop === void 0 ? void 0 : _vm$setting2$shop.accountHolder))]), _c('li', {
    staticClass: "font-size-md-16 grey--text text--darken-4"
  }, [_vm._v("은행명 : " + _vm._s((_vm$setting3 = _vm.setting) === null || _vm$setting3 === void 0 ? void 0 : (_vm$setting3$shop = _vm$setting3.shop) === null || _vm$setting3$shop === void 0 ? void 0 : _vm$setting3$shop.accountBankText))])])])])])], 1), _c('div', {
    staticClass: "txt txt--xs txt--light mt-8px"
  }, [_vm._v(" 2023년 10월 10일 15:00까지 입금 확인이 되어야 주문이 완료됩니다."), _c('br'), _vm._v(" 입금기한 내에 입금 확인이 되지 않을 경우, 주문이 취소됩니다."), _c('br'), _vm._v(" 자세한 주문내역 정보는 마이페이지>주문배송조회 페이지에서 확인하실 수 있습니다. ")])] : [_vm.success ? _c('div', {
    staticClass: "tit"
  }, [_vm._v("주문/결제가 완료되었습니다.")]) : _c('div', {
    staticClass: "tit"
  }, [_vm._v("주문/결제에 실패하였습니다.")]), _vm.success ? _c('div', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("결제가 완료되었습니다. 마이페이지에서 내용을 확인하세요.")]) : _c('div', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("[" + _vm._s(_vm.resCode) + "] " + _vm._s(_vm.resMessage))])], _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_vm.success ? _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/mypage/orders"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("주문내역 조회하기")])], 1) : _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("확인")])], 1)], 1)], 1)], 2)] : _vm._e(), _c('v-fade-transition', [_c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "size": "100",
      "width": "5"
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }