var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length ? _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": " 연관상품"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "value": _vm.product._id,
      "item-value": "_id",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "menu-props": {
        offsetY: true,
        contentClass: 'select-menu',
        closeOnClick: true,
        closeOnContentClick: true
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-list-item', {
          attrs: {
            "to": `/shop/products/${item._id}`,
            "disabled": !item.sells || item.stock == 0
          }
        }, [_c('related-group-item-content', {
          attrs: {
            "value": item
          }
        })], 1)];
      }
    }], null, false, 3036427389)
  }, 'v-select', {
    items: _vm.items
  }, false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }