var render = function render(){
  var _vm$category, _vm$category2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--contents flex-xl-nowrap"
  }, [_vm.$vuetify.breakpoint.xlOnly ? _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-240px"
  }, [_c('product-list-filter', _vm._g({
    staticClass: "d-none d-xl-block",
    attrs: {
      "categoryCode": _vm.$route.query.category
    }
  }, {
    search: _vm.search
  }))], 1)]) : _vm._e(), _c('v-col', [_c('div', {
    staticClass: "mb-20px"
  }, [_c('product-list-categories')], 1), _c('div', {
    staticClass: "mb-20px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-subtitle', {
    staticClass: "mb-0",
    attrs: {
      "titAlign": "text-center text-md-left"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm.$route.query.searchValue ? [_vm._v(" " + _vm._s(_vm.$route.query.searchValue) + " ")] : (_vm$category = _vm.category) !== null && _vm$category !== void 0 && _vm$category.name ? [_vm._v(" " + _vm._s((_vm$category2 = _vm.category) === null || _vm$category2 === void 0 ? void 0 : _vm$category2.name) + " ")] : [_vm._v(" 전체보기 ")]], 2), _c('template', {
    slot: "subTit"
  }, [_vm.$route.query.searchValue ? [_vm._v(" 키워드검색 ")] : _vm._e()], 2)], 2)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v(" 검색결과 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.summary.totalCount.format()))]), _vm._v(" 개 ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('product-list-sort-select')], 1), _c('v-col', {
    staticClass: "d-xl-none",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('product-list-filter', {
    attrs: {
      "categoryCode": _vm.$route.query.category
    }
  })], 1)], 1)], 1), _c('section', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists-body"
  }, [_c('shop-product-items', _vm._l(_vm.products, function (product, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": product
      }
    });
  }), 1), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }