<template>
    <div class="product-intro__info">
        <info-section hideDivider class="my-n16px my-sm-n24px">
            <v-row align="center" class="row--xxs">
                <v-col cols="auto">
                    <!-- 상품 chips -->
                    <info-groups v-bind="{ product }" />
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <!-- 상품 actions -->
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <info-like v-bind="{ product }" />
                        </v-col>
                        <v-col cols="auto">
                            <info-share v-bind="{ product }" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </info-section>

        <info-section>
            <!-- <p class="txt txt--xs txt--dark mb-4px">유한상사</p> -->
            <v-row no-gutters>
                <v-col>
                    <info-flags class="txt txt--sm txt--dark font-weight-bold mb-4px mb-md-8px" v-bind="{ product }" />
                    <h3 class="product-intro__name-text">{{ product.name }} {{ product.code || "" }}</h3>
                </v-col>
            </v-row>
            <div class="pt-16px pt-md-24px">
                <!-- 상품명 -->
                <info-rating v-bind="{ product }" />
            </div>
        </info-section>

        <!-- 상품 가격 -->
        <info-section>
            <info-price v-bind="{ product }" />
        </info-section>

        <!-- 배송정보 -->
        <info-section class="txt txt--xs">
            <info-shipping v-bind="{ product }" />
        </info-section>

        <div class="d-none d-lg-block">
            <slot name="form" />
            <slot name="actions" />
        </div>

        <slot name="foot" />
    </div>
</template>

<script>
import { initProduct } from "@/assets/variables";

import InfoSection from "./info/info-section.vue";

import InfoGroups from "./info/info-groups.vue";
import InfoLike from "./info/info-like.vue";
import InfoShare from "./info/info-share.vue";

import InfoFlags from "./info/info-flags.vue";
import InfoRating from "./info/info-rating.vue";

import InfoPrice from "./info/info-price.vue";
import InfoShipping from "./info/info-shipping.vue";

export default {
    components: {
        InfoSection,

        InfoGroups,
        InfoLike,
        InfoShare,

        InfoFlags,
        InfoRating,

        InfoPrice,
        InfoShipping,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        bottomNav: false,
    }),
};
</script>

<style></style>
