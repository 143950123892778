<template>
    <div v-scroll="onScrollFixed" class="scroll-fixed-wrap">
        <div class="scroll-fixed">
            <v-container class="container--lg">
                <!-- S: PC상단 고정영역 -->
                <div class="product-top-fixed">
                    <!-- S: PC상단 고정영역(항상보임) -->
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-card rounded>
                                <v-img contain width="48" :aspect-ratio="1 / 1" :src="product.thumb?.url" />
                            </v-card>
                        </v-col>
                        <v-col cols="">
                            <nav-price v-bind="{ product }" />
                        </v-col>
                        <v-col cols="auto">
                            <template v-if="productTopFixedBtn">
                                <v-btn large color="grey darken-4" class="min-w-200px" @click="((productTopFixedView = true) || true) && (productTopFixedBtn = false)">바로구매</v-btn>
                            </template>
                            <template v-else>
                                <v-btn x-small icon class="transparent" @click="((productTopFixedView = false) || true) && (productTopFixedBtn = true)">
                                    <v-icon size="24" color="grey">mdi-close</v-icon>
                                </v-btn>
                            </template>
                        </v-col>
                    </v-row>
                    <!-- E: PC상단 고정영역(항상보임) -->

                    <!-- S: PC상단 고정영역(바로구매버튼 클릭시 보임) -->
                    <v-divider v-show="productTopFixedView" class="my-24px" />
                    <div v-show="productTopFixedView" class="product-top-fixed-view">
                        <div class="overflow-hidden">
                            <v-row>
                                <v-col cols="4">
                                    <!-- 배송정보 -->
                                    <div class="txt txt--xs">
                                        <info-shipping v-bind="{ product }" />
                                    </div>
                                </v-col>
                                <v-col cols="4">
                                    <slot name="form" />
                                </v-col>
                                <v-col cols="4">
                                    <slot name="actions" />
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <!-- E: PC상단 고정영역(바로구매버튼 클릭시 보임) -->
                </div>
                <!-- E: PC상단 고정영역 -->

                <scrollactive :offset="$vuetify.breakpoint.lgAndUp ? 186 : 100" :scrollOffset="$vuetify.breakpoint.lgAndUp ? 186 : 100" :highlightFirstItem="true" active-class="v-tab--active" class="v-tabs">
                    <router-link to="#review" class="scrollactive-item v-tab">
                        상품평<span class="d-none d-lg-inline-block">({{ summary?.reviews?.format?.() || 0 }})</span>
                    </router-link>
                    <router-link to="#detail" class="scrollactive-item v-tab">상품상세</router-link>
                    <router-link to="#qna" class="scrollactive-item v-tab">
                        상품문의<span class="d-none d-lg-inline-block">({{ summary?.inquires?.format?.() || 0 }})</span>
                    </router-link>
                    <router-link to="#delivery" class="scrollactive-item v-tab">배송/교환/반품 정보</router-link>
                </scrollactive>
            </v-container>
        </div>
    </div>
</template>

<script>
import { initProduct } from "@/assets/variables";

import NavPrice from "./nav/nav-price.vue";
import InfoShipping from "./info/info-shipping.vue";

export default {
    components: {
        NavPrice,
        InfoShipping,
    },

    props: {
        product: { type: Object, default: initProduct },
        summary: { type: Object, default: () => ({}) },
    },
    data: () => ({
        productTopFixedBtn: true,
        productTopFixedView: false,
    }),
    mounted() {
        this.$nextTick(this.onScrollFixed);
    },
    methods: {
        onScrollFixed() {
            let scrollPosition = 0,
                mobileWidth = 1024;

            let scrollFixedWrap = document.querySelector(".scroll-fixed-wrap"),
                scrollFixed = document.querySelector(".scroll-fixed"),
                absoluteTop = 0,
                headerHeight = 50,
                tabHeight = 50;
            if (window.innerWidth > mobileWidth) {
                headerHeight = 114;
            }

            scrollPosition = window.scrollY || window.pageYOffset;
            if (window.innerWidth > mobileWidth) {
                // PC
                absoluteTop = window.pageYOffset + scrollFixedWrap.getBoundingClientRect().top - headerHeight + tabHeight;
            } else {
                // Mobile
                absoluteTop = window.pageYOffset + scrollFixedWrap.getBoundingClientRect().top - tabHeight;
            }

            if (scrollPosition < absoluteTop) {
                scrollFixed.classList.remove("active");
            } else {
                scrollFixed.classList.add("active");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll-fixed-wrap {
    --tab-height: 50px;
    height: var(--tab-height);
    transition: 0.25s ease-out;
    .v-tabs {
        display: flex;
        height: var(--tab-height);
        .v-tab {
            width: 25% !important;
            max-width: none;
            height: 100%;
        }
    }
    .v-tab {
        &--active {
            color: var(--v-grey-darken4);
        }
    }
}
.scroll-fixed {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--border-color);
    }
    &::before {
        bottom: 0;
    }
    &::after {
        display: none;
        top: 0;
    }
    &.active {
        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    &:not(.active) {
        .container {
            // max-width: var(--container);
            max-width: 1330px;
            padding: 0;
        }
    }

    ::-webkit-scrollbar {
        width: 12px; /* 스크롤바의 너비 */
    }
    ::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: var(--v-grey-lighten2); /* 스크롤바의 색상 */
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
        background: var(--v-grey-lighten4); /*스크롤바 뒷 배경 색상*/
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
}
.scrollactive-nav {
    position: relative;
    margin-top: auto;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .scroll-fixed-wrap {
    }
    .scroll-fixed {
        &::after {
            display: block;
        }
        &.active {
            top: 0;
            min-height: 114px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 576px) {
    .scroll-fixed {
        .container {
            padding: 0;
        }
    }
}
</style>
